import addToMailchimp from 'gatsby-plugin-mailchimp';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Styles from './mailchimp-signup.module.scss';

export default props => {
    const [formValue, setFormValue] = useState({
        firstName: '',
        email: ''
    });
    const [isLoading, setLoading] = useState(false);
    const [submitResult, setSubmitResult] = useState(null);

    const _handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        document.querySelector('button[type="submit"]').setAttribute('disabled', 'true');
        const result = await addToMailchimp(formValue.email, {
            PATHNAME: window.location.href,
            FNAME: formValue.firstName
        });
        setSubmitResult(result);
        setLoading(false);
    };

    const _handleChange = event => {
        // Update the form state
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const newValue = { ...formValue };
        newValue[name] = value;
        setFormValue(newValue);

        // Update form validity
        const form = document.getElementById('newsletter-form');
        const button = document.querySelector('button[type="submit"]');
        if (form.checkValidity()) {
            button.removeAttribute('disabled');
        } else {
            button.setAttribute('disabled', 'true');
        }
    };

    useEffect(() => {
        document.querySelector('button[type="submit"]').setAttribute('disabled', 'true');
    }, [])

    return (
        <div className={`text-white ${Styles.gradient} ${props.outerClass || ''}`}>
            <Container className="position-relative">
                <img className={`${Styles.planes}`} src="/images/planes.svg" height="200px" alt=""></img>
                <Row className="justify-content-center py-4">
                    <Col xs="12" md={props.alwaysVertical ? '12' : '6'} className={`mb-4 ${props.alwaysVertical ? '' : 'mb-md-0'}`}>
                        <h2 className="font-weight-bold text-white">{props.slice.primary.title1.text}</h2>
                        <div dangerouslySetInnerHTML={{ __html: props.slice.primary.text.html }}></div>
                    </Col>
                    <Col xs="12" md={props.alwaysVertical ? '12' : '6'}>
                        <form id="newsletter-form" onSubmit={_handleSubmit} className={`${submitResult ? 'd-none' : ''}`}>
                            <div className="">
                                <input type="text" required className={`${Styles.input} mb-3`} id="firstName" name="firstName" placeholder="Vorname" onChange={_handleChange} autoComplete="name given-name" />
                            </div>
                            <div className="">
                                <input type="email" required className={`${Styles.input} mb-4`} id="email" name="email" placeholder="E-Mail-Adresse" onChange={_handleChange} autoComplete="email" />
                            </div>
                            <div className={`${props.alwaysVertical ? 'text-center' : ''}`}>
                                <button className={`${Styles.button}`} type="submit">Jetzt eintragen</button>
                            </div>
                        </form>
                        <p className={`${isLoading ? 'd-block' : 'd-none'}`}>
                            Bitte warten...
                        </p>
                        <p className={`${!isLoading && submitResult && submitResult.result === 'error' ? 'd-block' : 'd-none'}`}>
                            Die Anmeldung ist fehlgeschlagen. Bitte versuche es später erneut.
                        </p>
                        <p className={`${!isLoading && submitResult && submitResult.result !== 'error' ? 'd-block' : 'd-none'}`}>
                            Danke für deine Anmeldung! Du wirst in Kürze eine Bestätigung per E-Mail erhalten.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
